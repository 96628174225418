
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { type ReactElement } from 'react';
import { ApolloProvider } from '@apollo/client';
import { type EmotionCache } from '@emotion/react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ErrorBoundary } from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import { type AppContext, type AppProps } from 'next/app';
import Head from 'next/head';
import { NotificationProvider } from '@credit-app/shared-ui';
import { Fallback } from '^/components/Fallback';
import useClient from '^/shared/graphql/apollo-client-side';
import { CustomLuxonUtils } from '^/shared/utils/CustomLuxonUtils';
import getTheme from '^/shared/utils/getTheme';
App.getInitialProps = async (appContext: AppContext) => {
    const host = appContext?.ctx?.req?.headers.host;
    const locale = appContext?.router?.locale;
    return { props: { host, locale } };
};
function App({ Component, pageProps, props, ...rest }: AppProps & {
    emotionCache?: EmotionCache;
    props: {
        host: string;
        locale: string;
    };
}): ReactElement {
    const { t } = useTranslation('common');
    const apolloClient = useClient(pageProps);
    const theme2 = getTheme(props.host, props.locale);
    return (<AppCacheProvider {...rest}>
      <Head>
        <title>{t('appTitle')}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width"/>
        <meta http-equiv="content-language" content={`${props.locale}`}></meta>
      </Head>
      <main className="app">
        <LocalizationProvider dateAdapter={CustomLuxonUtils}>
          <ThemeProvider theme={theme2}>
            <CssBaseline />
            <ApolloProvider client={apolloClient}>
              <NotificationProvider>
                <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        }}>
                  <ErrorBoundary fallback={({ resetError }) => (<Fallback resetError={resetError}/>)}>
                    <Component {...pageProps}/>
                  </ErrorBoundary>
                </Box>
              </NotificationProvider>
            </ApolloProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </main>
    </AppCacheProvider>);
}
const __Next_Translate__Page__1927deef447__ = App;

    export default __appWithI18n(__Next_Translate__Page__1927deef447__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  