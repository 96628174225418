import {
  type Theme,
  createTheme as createMuiTheme,
} from '@mui/material/styles';
import { Lato, Montserrat } from 'next/font/google';

/**
 * First version of the theme
 */
const lato = Lato({
  subsets: ['latin'],
  weight: ['400', '700'],
});

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
});

const {
  palette: { augmentColor },
} = createMuiTheme();
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const extendTheme = (theme: Theme) => {
  theme.typography.hero = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '36px',
    lineHeight: '43.2px',
  };

  theme.typography.hero2 = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: '30px',
  };

  theme.typography.hero3 = {
    ...lato.style,
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '20px',
  };

  theme.typography.h1 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
  };
  theme.typography.h2 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
  };
  theme.typography.h3 = {
    ...montserrat.style,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
  };
  theme.typography.h4 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
  };
  theme.typography.h5 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21.6px',
  };
  theme.typography.h6 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
  };

  theme.typography.body1 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
  };
  theme.typography.smallText = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
  };
  theme.typography.body3 = {
    ...lato.style,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
  };

  return theme;
};

export const createThemeV1 = (): Theme =>
  extendTheme(
    createMuiTheme({
      palette: {
        primary: {
          main: '#f64f6f',
        },
        secondary: {
          dark: '#1B4B95',
          main: '#3db3f5',
          light: '#C1E0F7',
        },
        backgroundMain: '#1D3963',
        highlight: '#E0EBFD',
        info: {
          main: '#0288d1',
        },
        link: '#2151C5',
        secondaryGray: { main: '#F1F2F4', hover: '#DDDFE4' },
        tableBorder: { main: '#2e2e2e', light: '#e0e0e0' },
        tableRowHover: { main: '#f9f9fa' },
        secondaryBlue: { main: '#20254F' },
        worksheetChangedItemBackground: { main: '#E7F5FF' },
        secondaryDark: createColor('#1B4B95'),
        white: {
          main: '#ffffff',
        },
        gray: {
          light: '#F2F2F2',
          main: '#808080',
        },
        badge: {
          APPROVED: '#1B4B95',
          BOOKED: '#000000',
          DECLINED: '#EA3006',
          DRAFT: '#E0E0E0',
          CONDITIONALLY_APPROVED: '#2C821E',
          INCOMPLETE: '#FF7F05',
          DOCUMENTS_INCOMPLETE: '#682C76',
          AWAITING_DECISION: '#FFD25D',
          PENDING_BANK_CONNECTION: '#797978',
          WITHDRAWN: '#E693D7',
        },
        hitType: {
          NO_CREDIT_FILE_REQUESTED: '#FF7F05',
          HIT: '#46CF30',
          NO_HIT: '#F9471F',
          MANUAL_FILE: '#9B51E0',
          CONSUMER_DECLARATION: '#FFD25D',
          REFERRED_FILE: '#1B4B95',
          CONTACT_BUREAU_COLLECTION_DEPARTMENT: '#FF7F05',
          CREDIT_LOCK: '#FF7F05',
        },
        incomeStatus: {
          WAIVED: '#2C821E',
          VERIFIED: '#2C821E',
          REQUIRED: '#F4CC65',
          UNKNOWN: '#A0A0A0',
        },
        incomeStatusChip: {
          WAIVED: '#B5F087',
          VERIFIED: '#B5F087',
          REQUIRED: '#FFEA80',
        },
        worksheetStatus: {
          approved: '#1B4B95',
        },
        custom: {
          text: {
            main: '#4B5663',
          },
        },
        bullet: {
          orange: '#FF9F1C',
          yellow: '#F65296',
          pink: '#FFC735',
        },
        progressBar: '#91E283',
        chat: {
          received: '#d9d9d9',
          sent: '#dbedff',
          selected: '#e6f2ff',
          system: '#fddce2',
          suggestion: '#fddce2',
        },
        notificationIcon: '#3DB3F5',
        heroText: '#FFC735',
      },
      typography: {
        button: {
          textTransform: 'none',
        },
        fontFamily: [lato.style.fontFamily, montserrat.style.fontFamily].join(
          ','
        ),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 28,
            },
            sizeLarge: {
              minWidth: '210px',
              height: '38px',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: '#db3131',
              fontSize: '1.7rem',
              '&$error': {
                color: '#db3131',
              },
            },
          },
        },
      },
    })
  );
