import { type ReactElement, useEffect, useState } from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { setCookie } from 'cookies-next';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export const LanguageSelector = (): ReactElement => {
  const [anchorElLocale, setAnchorElLocale] = useState<null | HTMLElement>(
    null
  );
  const [currentLocale, setCurrentLocale] = useState<string>('en');
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLocale(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElLocale(null);
  };
  const open = Boolean(anchorElLocale);
  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    // Close the menu when the locale changes
    handleCloseUserMenu();
    // detect the current locale and set the anchorElLocale to the correct language
    setCurrentLocale(router.locale ?? 'en');
    // get host from the router
    setCookie('NEXT_LOCALE', router.locale ?? 'en', {
      domain: window.location.hostname,
    });
  }, [router.locale]);

  const { pathname, asPath, query } = router;
  return (
    <Box sx={{ flexGrow: 0, ml: 5 }}>
      <Tooltip title={t('languageSelector')}>
        <Button
          onClick={handleOpenUserMenu}
          startIcon={<LanguageIcon />}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {t(currentLocale.toUpperCase())}
        </Button>
      </Tooltip>
      <Menu
        id="menu-locales-selector"
        anchorEl={anchorElLocale}
        keepMounted
        open={Boolean(anchorElLocale)}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() =>
            router.push({ pathname, query }, asPath, { locale: 'en' })
          }
          selected={currentLocale === 'en'}
          disabled={currentLocale === 'en'}
        >
          <Typography>{t('locales.canadaEN')}</Typography>
        </MenuItem>
        <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
        <MenuItem
          onClick={() =>
            router.push({ pathname, query }, asPath, { locale: 'fr' })
          }
          selected={currentLocale === 'fr'}
          disabled={currentLocale === 'fr'}
        >
          <Typography>{t('locales.canadaFR')}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
